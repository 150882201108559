import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import pie_chart from "../images/pie_chart.svg"

import openGpaphImage from "../images/opengraph/main.jpg"



const IndexPage = () => (
  <Layout>
    
    <section className="welcome">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-12 col-12 text-center">
            <h1>Ensure the safety of your apps using the battle-tested access authorization system</h1>
            <p className="intro_p">Try the fast-start authorization service which allows to get a multi-tenant authorization system inside of the application.</p>
            <Link to="https://cloud.iamcore.io/" className="btn btn-bright">Get started</Link>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container ">
        <div className="container-intro shadow">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-12">
              <h4>Building any Saas product is much slower as can be due to engineers wasting time on authorization functionality</h4>
              <p>Set up the user and permission management in minutes. Focus on your product's business value and save months of boilerplate development.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <img src={pie_chart} alt='pie_chart' className="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section>
      <div className="container ">
        <div className="container-features">
          <div className="row">
            <div className="col-12 text-center">
              <h2>Features</h2>
            </div>

            <div className="col-lg-4 mb-3 d-flex align-items-stretch">
              <div className="row feature-item feature-item-yel">
                  <div className="col-12 ">
                    <div className="tags-wrapper">
                      <div className="d-flex flex-wrap">
                        <div className="p-2">Granular resource-based access control</div>
                        <div className="p-2">RBAC, ABAC, ReBAC</div>
                        <div className="p-2">Multi-tenancy</div>
                        <div className="p-2">Cross-tenant resource sharing</div>
                        <div className="p-2">Pluggable external IDPs</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <p>Don't let your custom authorization code hold you back. Decouple policies from the code for lightning fast updates and future proof your app</p>
                  </div>
              </div>
            </div>

            <div className="col-lg-4 mb-3 d-flex align-items-stretch">
              <div className="row feature-item feature-item-secondary">
                  <div className="col-12 align-self-start">
                    <div className="tags-wrapper">
                      <div className="d-flex flex-wrap">
                        <div className="p-2">Policy debugging</div>
                        <div className="p-2">Access audit</div>
                        <div className="p-2">Permissions audit</div>
                        <div className="p-2">Wildcard matching for principals, resources and actions</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 align-self-end">
                    <p>Control user access at any granularity: tenant-, user group-, resource group-wide, or individually with iamcore flexible policy language that supports wildcards</p>
                  </div>
              </div>
            </div>

            <div className="col-lg-4 mb-3 d-flex align-items-stretch">
              <div className="row feature-item feature-item-devider">
                  <div className="col-12 align-self-start">
                    <div className="tags-wrapper">
                      <div className="d-flex flex-wrap">
                        <div className="p-2">Open APIs and opensource SDKs for all mainstream languages</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 align-self-end">
                    <p>Built by developers, for developers, iamcore API and SDKs offer intuitive integration options for hassle-free authorization</p>
                  </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    {/* <section>
      <div className="container container-trusted">
        <div className="row">
          <div className="col-12 text-center">
            <h2>Trusted by</h2>
            <img src={logos} alt='logo' className="img-fluid" />
          </div>
        </div>
      </div>
    </section> */}

    <section className="special-section">
      <div className="container ">
        <div className="container-special">

              <div className="row  align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-lg-end text-center">
                  <h2>Ready to try?</h2>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 special-btn-wrapper text-lg-start text-center">
                  <Link to='https://cloud.iamcore.io/' className="btn btn-bright">Get started</Link>
                </div>
              </div>

        </div>
      </div>
    </section>

  </Layout>
)

export const Head = () => <Seo title="iamcore Access Authorization System" description="Ensure the safety of your apps using the battle-tested access authorization tool." image={openGpaphImage} />

export default IndexPage
